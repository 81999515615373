<template>
    <div class="addShop" >
        <Header backType="white" backEvent="true" @backEvent="backPage" :back="true"></Header>
        <div class="publicHead mt-2 mb1-5">
            <p>
                <span>自定义餐厅</span>
            </p>
        </div>
        <div class="rule" @click="open">上线基本规则<Svgs color="#000" classes="downIcon" name="iconfontdown"></Svgs></div>
        <div class="mui-content">   
            <div class="normalInputRow2" @click="into">
                <MInput title="城市" disabled v-model="shop.cityVal"></MInput>
            </div> 
            <div class="normalInputRow2" @click="into">
                <MInput title="餐厅名称" disabled pla="请输入详细名称,如:全聚德（闸北店)/必填" v-model="form.name"></MInput>
            </div>
            <div class="normalInputRow2" @click="into">
                <MInput title="餐厅地址" disabled pla="请输入详细地址,如:(澳门路100号)/必填" v-model="form.address"></MInput>
            </div>
            <div class="normalInputRow2" @click="into">
                <MInput title="餐厅电话" pla="请输入正确格式,如:(0377-8129)" v-model="form.tel" ></MInput>
            </div>
            <div class="opCreateBtnRow" id="submitBtn">
                <Btn btnClass="opCreateBtn" @callBack="submitEvent">
                    <span slot="btnContent">{{ isPfizer ? '保存' : '提交'}}</span>
                </Btn>
            </div>
        </div>
        

        <MessageBox  ref="msg" type="tips" :htmlMessage="true" messageTitle="餐厅上线基本规则" btnType="single" singleTxt="已知晓" @onSingle="msgBack">
            <div slot="htmlSlot" v-html="wikiContent">
            </div>
        </MessageBox>
        <MessageBox ref="successMsg" type="tips" :htmlMessage="true" btnType="single" singleTxt="确定" @onSingle="successMsgBack">
            <div slot="htmlSlot">
                <p>您推荐的餐厅已提交成功</p>
                <p>我们将会对您推荐的餐厅进行线上审核：</p>
                <div v-html="wikiContent"></div>
            </div>
        </MessageBox>
        <MessageBox ref="errorMsg" type="tips" :messageTxt="errorMsg"  btnType="single" singleTxt="确定" @onSingle="errorMsgBack"></MessageBox>
    </div>
</template>

<script>
import {SmartStorage} from 'smart-core-util'
import { miceService } from "@/service/miceService.js";
import { mapGetters, mapMutations } from "vuex";
import { getCookie } from 'tiny-cookie'
import Dialog from "vant/lib/dialog";
export default {
    components:{
    },
    computed: {
        ...mapGetters(["shop",'address'])
    },
    data(){
        return{
            isPfizer: false,
            clickClose:true,
            form:{
                name:'',
                address:'',
                tel:''
            },
            errorMsg:'',
            wikiContent: ""
        }
    },
    created() {
        this.$fixAndroidInput({
            wrapper: "event-detail-box",
        });
        // this.isPfizer = ['pfizer'].includes(SmartStorage.get('tenant_code'))
        this.isPfizer = false
    },
    mounted(){
        this.queryOnlineRules()
        this.form.name = this.shop.fullName
        this.form.address = this.shop.address
        this.form.tel = this.shop.tel
    },
    methods:{
        ...mapMutations(["SHOP"]),
        async queryOnlineRules() {
            let params = {
                "collection": 'cfg-ishop-wiki',
                filter: {
                    entryCode: "onlineRules",
                    tenantCode: SmartStorage.get('tenant_code')
                }
            }
            if(SmartStorage.get('gray') || getCookie('gray')){
                    params.filter.tenantCode = SmartStorage.get('tenant_code') + '-gray'
            }
            miceService.getsettings(params).then(res => {
                if(res.success) {
                    this.wikiContent = res.content.content
                    // this.$refs.msg.openPop();
                }
            });
        },
        backPage() {
            this.$router.go(-1);
        },
        async submitEvent(flag){
            if(['pfizer2'].includes(SmartStorage.get('tenant_code')) && !flag){
                Dialog.alert({
                    title: '提示',
                    message: '确认保存吗？',
                    showCancelButton: true
                }).then(() => {
                    this.submitEvent(true)
                });
                return
            }
            await this.saveShop()
            let params = {
                cityId: SmartStorage.get('cityId') || '',
                hospitalId: SmartStorage.get('hospitalId') || '',
                itemType: '5',
                cityName: this.shop.cityVal,
                shops:[
                    {
                        name: this.form.name,
                        tel: this.form.tel,
                        address: this.form.address,
                        shopId:null,
                        rowId:0,
                        recommendType:2,
                        imgThumbnail:"",
                        priceAvgVal:0,
                        regionVal:"",
                        categoryVal:"",
                        checked:false
                    }
                ],
                channel: 'app'
            }
            miceService.PostMyShops(params).then(res => {
                if(res.success){
                    if(res.content.status){
                        if(!['pfizer2'].includes(SmartStorage.get('tenant_code'))){
                            this.$refs.successMsg.openPop();
                        }else{
                            this.successMsgBack()
                        }
                    }else{
                        this.$refs.errorMsg.openPop();
                        this.errorMsg = res.content.msg
                    }
                }
            })
        },
        open(){
            this.$refs.msg.openPop();
        },
        msgBack(){
            // this.$refs.msg.cancel();
        },
        successMsgBack(){
            // this.$refs.successMsg.cancel();
            this.$router.push('/recommendShop');
        },
        errorMsgBack(){
            // this.$refs.errorMsg.cancel();
        },
        into(){
            var h = document.body.scrollHeight;
            window.onresize = function(){
                if (document.body.scrollHeight < h) {
                    document.getElementById('submitBtn').style.display = 'none';
                }else{
                    document.getElementById('submitBtn').style.display = 'block';
                }
            };
        },
        // 高德保存餐厅
        saveShop(){
            let params = this.shop
            if(['pfizer'].includes(SmartStorage.get('tenant_code'))){
                this.shop.tel = this.form.tel
                params = {
                    ...this.shop,
                    AutoTenantMap: false
                }
            }
            return new Promise((resolve) =>
                miceService.saveGaoDeshop(params).then(res => {
                    if (res.success) {
                        resolve(res)
                    }
                })
            );
        }
    }
    
}
</script>

<style lang="scss">
.rightBtn{
    font-size: .12rem;
}
.messageTxt p{
    text-align: left;
}
.rule{
    text-align: left;
    margin-left: .25rem;
    font-size: .1rem;
    margin-bottom: .3rem;
}
.downIcon{
    width: .1rem!important;
    height: .1rem!important;
    margin-left: .05rem;
    vertical-align: middle;
}
.opCreateBtn {
    width: 88%;
    height: .45rem;
    line-height: .45rem;
    border-radius: .3rem;
    margin: 0 auto;
    font-size: .15rem;
}
.opCreateBtnRow{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: .2rem 0 .3rem 0;
    box-shadow: 1px -.15rem .15rem #fff;
}
</style>